export default {
    name: 'BTS',
    methods: {
        formatHashrate(value){
            // (<5000)GH/s, (>5000/1000 TH/s), (>5mln/mln PH/s), (>5*10^9/10^9 EH/s)
            let result = 0;
            let measure = "";
            let power = 0;
            if(value >= 5 * Math.pow(10, 9)){
                power = 9;
                measure = "Eh/s";
            }else if(value >= 5 * Math.pow(10, 6)){
                power = 6;
                measure = "Ph/s";
            }else if(value >= 5 * Math.pow(10, 3)){
                power = 3;
                measure = "Th/s";
            }
            result = Math.round(value/Math.pow(10, power));
            return `${result} ${measure}`;
        }
    },
};