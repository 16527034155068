<template>
  <CCard class="minersMonitoring-card">
    <CCardBody :class="card.color">
      <router-link
          :to="'/MinerInfo/' + this.card.miner_id"
        class="position-relative table-responsive mb-0 text-decoration-none"
      >
        <table class="table">
          <tbody class="position-relative">
            <tr>
              <td class="p-0 border-0 minersMonitoring-td">
                  {{ card.ip_address }}
              </td>
              <td class="p-0 border-0 minersMonitoring-td text-right">
                {{ shortMinerName }}
              </td>
            </tr>
            <tr>
              <td class="p-0 border-0 minersMonitoring-td">
                  {{ formatHashrate(card.hashrate) }}
              </td>
              <td class="p-0 border-0 minersMonitoring-td">
                
              </td>
            </tr>
            <tr>
              <td class="p-0 border-0 minersMonitoring-td">
                  {{ card.temperature }}° / {{ calcHashrate(card) }}%
              </td>
              <td class="p-0 border-0 minersMonitoring-td">
              </td>
            </tr>
          </tbody>
        </table>
      </router-link>
    </CCardBody>
  </CCard>
</template>
<script>
import BTCMixin from './../../mixins/BTCMixin';
export default {
  name: "MinersMonitoringCard",
  mixins: [BTCMixin],
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  computed: {
    shortMinerName(){
      if(this.card && this.card.miner_name && this.card.miner_name.includes('-') && this.card.miner_name.length>=3)
        return `${this.card.miner_name.split('-')[2]}`;
      else if(this.card && this.card.miner_name && this.card.miner_name.length > 0)
        return `${this.card.miner_name.charAt(0)}-${this.card.miner_name.charAt(this.card.miner_name.length-1)}`;
      else
        return '';
    },
  },
  methods: {
    calcHashrate(obj) {
      return Math.floor((obj.hashrate * 100) / obj.exp_hashrate);
    },
  },
};
</script>
<style lang="scss" scoped>
  .btcminer-border-right-light{
    border-right: 1px solid white!important;
  }
</style>
