<template>
  <div class="c-app modal-vue container-page minersMonitoring-page bg-bg-gray">
    <CWrapper>
      <form class="c-miners-filtration" disabled>
        <CRow>
          <CCol md="2" class="pr-lg-0">
            <CSelect
              ref="sectionSelect"
              :label="i18n.t('Section')"
              :options="allSections"
              :value="filterFields.section"
              @update:value="updateSection"
              :readonly="loading"
            />
          </CCol>
          <CCol md="2" class="pr-lg-0">
            <label for="">{{ $t("Efficiency") }} </label>
            <div class="d-flex d-wrap align-items-center">
              <CInput
                placeholder=""
                v-model="filterFields.efficiency_min"
                type="number"
                :readonly="loading"
              />
              <span>-</span>
              <CInput
                placeholder=""
                v-model="filterFields.efficiency_max"
                type="number"
                :readonly="loading"
              />
            </div>
          </CCol>
          <CCol md="2" class="pr-lg-0" v-if="userInfo && userInfo.user && userInfo.user.role != 'customer'">
            <label for="">{{ $t('Customers') }}</label>
            <CSelect :options="allCustomers.map(res => {
                return {
                  ...res,
                  value: res.email
                }
              })"
              @update:value="updateCustomerChanged" />
          </CCol>
          <CCol md="2" class="pr-lg-0">
            <label for=""> {{ $t("Crt Hashrate Gh/s") }}</label>
            <div class="d-flex d-wrap align-items-center">
              <CInput
                placeholder=""
                v-model="filterFields.hashrate_min"
                type="number"
                :readonly="loading"
              />
              <span>-</span>
              <CInput
                placeholder=""
                v-model="filterFields.hashrate_max"
                type="number"
                :readonly="loading"
              />
              <!-- <CSelect
                :options="allHashrateMeasures"
                :value="0"
                @update:value="updateSection"
                :readonly="loading"
              /> -->
            </div>
          </CCol>
          <CCol md="2" class="pr-lg-0">
            <label for=""> {{ $t("Avg Temp, C") }}</label>
            <div class="d-flex d-wrap align-items-center">
              <CInput
                placeholder=""
                v-model="filterFields.temp_min"
                type="number"
                :readonly="loading"
              />
              <span>-</span>
              <CInput
                placeholder=""
                v-model="filterFields.temp_max"
                type="number"
                :readonly="loading"
              />
            </div>
          </CCol>
          <CCol md="2" class="pr-lg-0">
            <label for="">{{ $t("Errors") }}</label>
            <div class="d-flex d-wrap align-items-center">
              <CInput
                class="w-100"
                placeholder=""
                v-model="filterFields.errors"
                :readonly="loading"
              />
            </div>
          </CCol>
          <CCol md="2" class="pr-lg-0">
            <CSelect
              :label="i18n.t('Status')"
              :options="allColors"
              :value="filterFields.color"
              @update:value="updateColor"
              :readonly="loading"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="8"></CCol>
          <CCol md="2" class="pr-lg-0">
            <CButton
              class="button-blue text-uppercase"
              @click="requestMinersMonitoring"
              :disabled="loading"
              >{{ $t("apply") }}
            </CButton>
          </CCol>
          <CCol md="2" class="pr-lg-0">
            <CButton
              class="button-white text-uppercase"
              @click="resetFilters"
              :disabled="loading"
              >{{ $t("reset") }}</CButton
            >
          </CCol>
        </CRow>
      </form>
      <div class="c-title pb-4 mt-2">
        {{ selectedContainerName }}, {{ $t("Miner Monitoring") }}
      </div>
      <h1 v-if="loading" class="" style="color: black">Loading ...</h1>
      <div v-else v-for="(sobj, si) in data" :key="si" style="overflow-x: scroll">
        <div class="text-main_black mb-3 font-xl">
          {{ $t("Section") }} {{ sobj.section }}
        </div>
        <div
          class="row"
          v-for="(line, li) in getLineNumbers(sobj)"
          :key="li"
          style="width: 1160px"
        >
          <div class="col pt-3 pr-0" style="margin-right: -50px">
            {{ li + 1 }}
          </div>
          <div class="col" v-for="position in 10" :key="position">
            <miner-monitoring-card
              v-if="getDataFromInfo(si, li + 1, position)"
              :card="getDataFromInfo(si, li + 1, position)"
            >
            </miner-monitoring-card>
            <div v-else style="height: 58px; width: 103px"></div>
          </div>
        </div>
      </div>
    </CWrapper>
  </div>
</template>

<script>
//import Echo from 'laravel-echo'
import i18n from "../../i18n";
import axios from "axios";
import { mapActions, mapState } from "vuex";
import MinerMonitoringCard from "../Components/MinerMonitoringCard";

const items = [
  [
    {
      info: "31.21.11.1",
      value: "",
      // isHeader: true,
      // _classes: "header-hashrate",
    },
    {
      info: "85.08 TH/s",
      value: "[162]",
    },
    {
      info: "95° / 97%",
      value: "[191]",
    },
  ],
];

const fields = [
  {
    key: "info",
    filter: false,
    label: "",
    _classes: "p-0 border-0 minersMonitoring-td",
  },
  {
    key: "value",
    filter: false,
    label: "",
    _classes: "p-0 border-0 minersMonitoring-td",
  },
];

export default {
  name: "MinersMonitoring",
  components: {
    MinerMonitoringCard,
  },
  data() {
    return {
      i18n,
      loading: true,
      containerId: null,
      selectedContainerName: null,
      data: null,
      items,
      fields,
      // fields,
      details: [],
      collapseDuration: 0,
      page: 1,
      allSections: [],
      allColors: ["All", "green", "yellow", "red", "purple"],
      sectionsFetchedOnce: false,
      allHashrateMeasures: [
        {
          id: 0,
          value: "Gh/s",
        },
      ],
      filterFields: {
        section: "All",
        hashrate_min: null,
        hashrate_max: null,
        temp_min: null,
        temp_max: null,
        errors: "",
        color: "All",
        efficiency_min: null,
        efficiency_max: null,
      },
    };
  },
  mounted() {
    if (!this.newTick)
      this.newTick = setInterval(() => {
        this.requestMinersMonitoring();
      }, 60000);
  },
  destroyed() {
    clearInterval(this.newTick);
  },
  created() {
    this.curPage();
    //fetching cid from url
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.cid) this.containerId = parseInt(params.cid);
    if (params.cname) this.selectedContainerName = params.cname;
    //this.mapItems();
    this.requestMinersMonitoring();
    // Object.keys(this.$route.query).forEach(res => {
    //   this.filterFields[res] = this.$route.query[res];
    // });
    // this.$store.dispatch('miners/APPLY_FILTER_SECTION',
    //     {body: this.filterFields,page: this.$route.params.id}
    // ).then(() => {
    //   this.current['section'] = this.getCurrent(this.allSections,this.filterFields.section);
    // });
  },
  computed: {
    ...mapState("miners", [
      "allCustomers",
    ]),
    ...mapState("user",["userInfo"])
  },
  methods: {
    ...mapActions("miners", ["GET_ALL_CUSTOMERS"]),
    tickForMinerMonitoring() {
      this.requestMinersMonitoring();
    },
    getDataFromInfo(sectionIndex, line, position) {
      //console.log('data',this.data)
      if (this.data[sectionIndex]) {
        for (let i = 0; i < this.data[sectionIndex].data.length; i += 1) {
          let ll = this.data[sectionIndex].data[i];
          if (ll.line == line && ll.position == position) {
            return ll;
          }
        }
      }
      return null;
    },
    getLineNumbers(sobj) {
      if (sobj.data[sobj.data.length - 1]) return sobj.data[sobj.data.length - 1].line;
      else return 0;
    },
    curPage() {
      this.$emit("curPage", "monitoring");
    },
    mapItems() {
      this.items = this.items.map((obj) => {
        return obj.map((item, id) => {
          return { ...item, id };
        });
      });
    },
    getClass(item) {
      if (item === 0) return "hashrate-gray";
      else if (item > 0 && item <= 45) return "hashrate-red";
      else if (item > 45 && item <= 85) return "hashrate-yellow";
      else return "hashrate-green";
    },
    requestMinersMonitoring() {
      this.loading = true;
      axios({
        method: "post",
        url: process.env.VUE_APP_API + "/api/miner-monitoring",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("btsminer_token"),
        },
        data: {
          container_id: this.containerId,
          ...this.filterFields,
        },
      })
        .then((response) => {
          this.data = response.data;
          this.fetchAllSections();
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(() => {
          this.loading = false;
        });
    },
    resetFilters() {
      this.filterFields = {
        section: "All",
        hashrate_min: null,
        hashrate_max: null,
        temp_min: null,
        temp_max: null,
        errors: "",
      };
      this.requestMinersMonitoring();
    },
    fetchAllSections() {
      if (!this.sectionsFetchedOnce) {
        this.allSections = [];
        this.allSections[0] = "All";
        for (let i = 0; i < this.data.length; i++) {
          this.allSections[i + 1] = this.data[i].section;
        }
      }
      this.sectionsFetchedOnce = true;
    },
    updateSection(value, e) {
      const id = e.target.selectedOptions[0].dataset.key;
      this.filterFields.section = this.allSections[id];
    },
    updateColor(value, e) {
      const id = e.target.selectedOptions[0].dataset.key;
      this.filterFields.color = this.allColors[id];
    },
    updateCustomerChanged(value, e){
      this.filterFields.customer = this.allCustomers.filter(res => res.email == value)[0].id;
    }
  },
  watch: {
    'userInfo':{
      handler: function (e) {
        if(e.user){
          if(e.user.role != 'customer'){
            this.GET_ALL_CUSTOMERS()
          }
        }        
      },
      deep: true
    },
  },
  beforeDestroy: function () {
    this.selectedContainerName = null;
  },
};
</script>

<style scoped lang="sass">

.card
  border: none
  box-shadow: 0px 8px 16px rgba(214, 210, 210, 0.24)
  border-radius: 8px
  overflow: hidden

.card .table
  tr
    display: flex
    justify-content: space-between

  td
    width: max-content
    border: none
    padding: 0

  .td-value
    text-align: right

    span
      & + span
        margin-left: 8px

  .td-title
    font-weight: 500
    font-size: 20px

.miners
  &-well, &-danger, &-total
    padding: 4px 8px
    border-radius: 8px
    font-weight: 500
    font-size: 12px
    color: white

  &-well
    background: #09BB70

  &-danger
    background: #F42A4E

  &-total
    background: #C2C2C2

.edit-icon:hover svg path
  fill: #0F5B84

.delete-icon:hover svg path
  fill: #EC5252
  stroke: #EC5252
</style>
